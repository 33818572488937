<template>
  <UnauthorizedStyled>
    {{ $t('messages.unauthorized') }}
  </UnauthorizedStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { flexCenter } from '@styles/mixins'

const UnauthorizedStyled = styled('div')`
  ${flexCenter}
  height: 100%;
  font-size: 1.5rem;
`

export default {
  components: {
    UnauthorizedStyled,
  },
}
</script>
